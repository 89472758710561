import type { OpenAPIV3 } from 'openapi-types';

import { deepCopy } from '../utils';
import { Dereferenced } from './types';

type DereferencedSchemaObject = Dereferenced<OpenAPIV3.SchemaObject>;

export interface UnionSchema {
  type: 'anyOf' | 'oneOf';
  schemas: Schema[];
}

export type Schema = DereferencedSchemaObject | UnionSchema;

export function resolveSchema(schema: DereferencedSchemaObject): Schema {
  let updatedSchema: Schema = deepCopy(schema);

  if (updatedSchema.allOf) {
    const allOf = deepCopy(updatedSchema.allOf);
    delete updatedSchema.allOf;

    const subSchemas = allOf.map((subSchema) => resolveSchema(subSchema));
    updatedSchema = mergeSchemas(updatedSchema, subSchemas);
  }

  // anyOf or oneOf was present in allOf, returning these unions
  if (isUnionSchema(updatedSchema)) return updatedSchema;

  const unionType = updatedSchema.anyOf
    ? 'anyOf'
    : updatedSchema.oneOf && 'oneOf';
  if (!unionType) return updatedSchema;

  if (updatedSchema[unionType]) {
    // @ts-ignore updatedSchema[unionType] is not undefined
    const subSchemas = updatedSchema[unionType].map((subSchema) =>
      resolveSchema(deepCopy(subSchema)),
    );

    // Delete anyOf and allOf so that they don't get included in the schema merge
    delete updatedSchema.anyOf;
    delete updatedSchema.oneOf;

    const merged = subSchemas.map((subSchema) =>
      mergeSchemas(updatedSchema, [subSchema]),
    );

    return {
      type: unionType,
      schemas: merged,
    };
  }

  return updatedSchema;
}

function mergeSchemas(
  schema: DereferencedSchemaObject,
  subSchemas: Schema[],
): Schema {
  if (subSchemas.length === 0) return schema;

  // If there exists a union schema only one can be part of the merge result, the others must be disregarded.
  const unionSchema = subSchemas.find(isUnionSchema);
  if (unionSchema) {
    const nonUnionSubSchemas = subSchemas.filter(
      (subSchema): subSchema is DereferencedSchemaObject =>
        !isUnionSchema(subSchema),
    );
    const mergedSchema = nonUnionSubSchemas.reduce(
      (mergedSchema, subSchema) => mergeSchema(mergedSchema, subSchema),
      schema,
    );

    return {
      type: unionSchema.type,
      schemas: unionSchema.schemas.map((unionSubSchema) =>
        mergeSchemas(mergedSchema, [unionSubSchema]),
      ),
    };
  } else {
    const nonUnionSubSchemas = subSchemas as DereferencedSchemaObject[];
    return nonUnionSubSchemas.reduce(
      (mergedSchema, subSchema) => mergeSchema(mergedSchema, subSchema),
      schema,
    );
  }
}

function mergeSchema(
  schema1: DereferencedSchemaObject,
  schema2: DereferencedSchemaObject,
): DereferencedSchemaObject {
  const type = schema1.type || schema2.type;

  if (type === 'object') {
    return mergeObjectSchemas(schema1, schema2);
  }

  const schema1Copy = deepCopy(schema1);
  const schema2Copy = deepCopy(schema2);
  // If there are overlapping properties then the property of `schema1` will have precedence.
  return { ...schema2Copy, ...schema1Copy };
}

/**
 * Takes in two schemas and returns a copy of the merged schemas without changing any of the original schemas.
 * If there are overlapping properties then the property of `schema1` will have precedence.
 */
function mergeObjectSchemas(
  schema1: DereferencedSchemaObject,
  schema2: DereferencedSchemaObject,
): DereferencedSchemaObject {
  const schema1Copy = deepCopy(schema1);
  const schema2Copy = deepCopy(schema2);
  // Overlapping properties from schema1 will take precedence
  const mergedSchema = { ...schema2Copy, ...schema1Copy };

  // Complex properties to support:
  //   default?: any;
  //   additionalProperties?: boolean | SchemaObject;
  //   required?: string[];
  //   enum?: any[];
  //   not?: SchemaObject;
  //   discriminator?: DiscriminatorObject;
  //   xml?: XMLObject;
  //   externalDocs?: ExternalDocumentationObject;
  //   example?: any;

  if (schema2Copy.properties) {
    Object.entries(schema2Copy.properties).forEach(([key, value]) => {
      if (mergedSchema.properties && !mergedSchema.properties[key]) {
        mergedSchema.properties[key] = value;
      }
    });
  }

  return mergedSchema;
}

export function isObjectOrArraySchema(
  schema: Schema,
): schema is DereferencedSchemaObject {
  return schema.type === 'object' || schema.type === 'array';
}

export function isUnionSchema(schema: Schema): schema is UnionSchema {
  return Object.hasOwn(schema, 'schemas');
}

export function isDereferencedSchema(
  schema: Schema,
): schema is DereferencedSchemaObject {
  return !Object.hasOwn(schema, 'schemas');
}
