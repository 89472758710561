import { Div, Flex, H1 } from '@dnb/eufemia';

import ApiStatusWidget from '../ApiStatusWidget';

import style from './LandingHeader.module.css';

export default function LandingHeader(): JSX.Element {
  return (
    <Div className={style['LandingHeroContainer']} id="HeroHeader">
      <Flex.Horizontal
        align="center"
        className={style['LandingHeroContent']}
        gap={false}
        justify="space-evenly"
      >
        <Div className={style['HeroText']}>
          <H1>Welcome to the developer portal</H1>
        </Div>
        <ApiStatusWidget />
      </Flex.Horizontal>
    </Div>
  );
}
