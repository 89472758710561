import { heart, loan, newspaper, play } from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import { FeatureFlagDto } from '@portals/shared/portal/FeatureFlagDto';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { useMemo } from 'react';

import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useUser } from '@/hooks/useUser';

import NavigationButton, { NavigationButtonProps } from '../NavigationButton';

import style from './index.module.css';

function navigationUris(
  featureFlags: FeatureFlagDto,
  isDnbUser: boolean,
): NavigationButtonProps[] {
  const links: NavigationButtonProps[] = [
    {
      title: 'Documentation',
      icon: newspaper,
      links: [
        {
          title: 'Regulatory APIs',
          to: '/explorer/apis',
          subTitle: 'PSD2',
          isVisible: true,
        },
        {
          title: 'Commercial APIs',
          to: '/explorer/apis',
          subTitle: 'Non-regulatory APIs',
          isVisible: true,
        },
        {
          title: 'Internal APIs',
          to: '/explorer/apis/internal',
          isVisible: isDnbUser,
        },
        {
          title: 'API contracts',
          to: '/api-contracts',
          subTitle: 'All API contracts in Gitlab',
          isVisible: featureFlags.ENABLE_COMMON_REPO_API && isDnbUser,
        },
        {
          title: 'Scope overview',
          to: '/scope-overview',
          subTitle: 'Scopes available in ciam',
          isVisible: featureFlags.ENABLE_SCOPE_OVERVIEW && isDnbUser,
        },
      ],
    },
    {
      title: 'Get Started',
      icon: play,
      links: [
        { title: 'Getting Started', to: '/getting-started', isVisible: true },
      ],
    },
    {
      title: 'Digital Channel Statistics',
      icon: loan,
      links: [
        {
          title: 'Digital Channel Statistics',
          to: '/channel-statistics',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Support',
      icon: heart,
      links: [
        { title: 'API status', to: '/status', isVisible: true },
        { title: 'News & updates', to: '/news', isVisible: true },
        {
          title: 'Help center',
          to: '/help-center',
          subTitle: 'Need help or want to report something?',
          isVisible: true,
        },
        {
          title: 'FAQ',
          to: '/help-center/faq/psd2',
          subTitle: 'Find answers to your questions in our FAQ',
          isVisible: true,
        },
      ],
    },
  ];

  const linkConstructor: NavigationButtonProps[] = links.map(
    (linkObject): NavigationButtonProps => ({
      links: linkObject.links!.filter((link) => link.isVisible),
      title: linkObject.title,
      icon: linkObject.icon,
    }),
  );

  return linkConstructor;
}

export default function NavigationButtonMenu() {
  const { featureFlags } = useFeatureFlags();
  const { isLarge } = useMedia();
  const { user, isLoading: userLoading } = useUser();
  const isDnbUser = isInternalUser(user?.email) && !userLoading;
  const menuLinks = useMemo(
    () => navigationUris(featureFlags, isDnbUser),
    [featureFlags, isDnbUser],
  );

  return (
    <div className={isLarge ? style['MenuLinks'] : style['hamburgerDialog']}>
      {menuLinks.map((uri) => (
        <NavigationButton
          icon={uri.icon}
          key={uri.title}
          links={uri.links}
          title={uri.title}
        />
      ))}
    </div>
  );
}
