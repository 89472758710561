import {
  cloud,
  file_contract,
  file_loupe,
  layout_card,
  play,
} from '@dnb/eufemia/icons';
import { isInternalUser } from '@portals/shared-frontend/utils';

import MenuBar from '@/components/MenuBar';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useUser } from '@/hooks/useUser';

import Tile from '../../components/Tile';
import InformationBox from './InformationBox/InformationBox';
import LandingHeader from './LandingHeader/LandingHeader';

import style from './Landing.module.css';

import Inbox from '../../illustrations/Inbox.svg?url';

export default function LandingNew(): JSX.Element {
  const { user } = useUser();
  const isDnbUser = isInternalUser(user?.email);
  const { featureFlags } = useFeatureFlags();
  return (
    <header className={style['HeroHeader']}>
      <MenuBar />
      <LandingHeader />
      <div className={style['LandingContent']} id="LandingContent">
        <div className={style['LandingContentFlex']}>
          <Tile
            description="PSD2"
            icon={file_contract}
            linkTo="/explorer/apis/internal"
            title="Regulatory APIs"
          />
          <Tile
            description="Non-regulatory APIs"
            icon={cloud}
            linkTo="/explorer/apis"
            title="Commercial APIs"
          />
          <Tile
            description="Description"
            icon={play}
            linkTo="/getting-started"
            title="Get started"
          />
          {isDnbUser && (
            <>
              {featureFlags.ENABLE_COMMON_REPO_API && (
                <Tile
                  description="DNB common contract repo"
                  icon={layout_card}
                  linkTo="/api-contracts"
                  title="API contracts"
                />
              )}
              {featureFlags.ENABLE_API_REVIEW_PAGE && (
                <Tile
                  description="Visible to API custodians only."
                  icon={file_loupe}
                  linkTo="/apis/review"
                  title="APIs in review"
                />
              )}
            </>
          )}
        </div>
      </div>
      <InformationBox
        buttonText="News page"
        description="Keep up with new features and changes to the developer portal."
        illustration={Inbox}
        title="Check out our news page"
      />
    </header>
  );
}
