import { Dropdown, Space, Tabs } from '@dnb/eufemia';
import type { OpenAPIV3 } from 'openapi-types';
import { useEffect, useState } from 'react';

import Markdown from '@/components/Markdown';
import type { DereferencedSchemaObject } from '@/pages/api-documentation/constants/types';

import Example from '../Example';
import SchemaItem from '../Schema/components/SchemaItem';

import { H3S } from '@/pages/api-documentation/styles/Api.Reference.styles';

type RequestBodyProps = {
  data: OpenAPIV3.RequestBodyObject;
};

const RequestBody = ({ data }: RequestBodyProps) => {
  const { content } = data;
  const [selected, setSelected] = useState(
    content ? Object.keys(content)[0] : '',
  );
  const [activeExample, setExample] = useState('');
  const section = content
    ? (content[selected]['schema'] as DereferencedSchemaObject)
    : null;
  const examples = content ? content[selected]['examples'] : null;
  const [tab, selectTab] = useState('schema');

  useEffect(() => {
    if (tab === 'example' && examples) {
      setExample(Object.keys(examples)[0]);
    }
  }, [tab]);

  return (
    <div>
      <H3S>Request body</H3S>
      {data.description && <Markdown>{data.description}</Markdown>}
      {content && (
        <div>
          <Space bottom="small" top="small">
            <Dropdown
              data={Object.keys(content).map((item, index) => ({
                selected_key: `key_${index}`,
                content: item,
              }))}
              on_change={({ data }) => {
                setSelected(data.content);
              }}
              value="key_0"
            />
          </Space>
          <Tabs
            data={[
              { title: 'Schema', key: 'schema' },
              { title: 'Example', key: 'example' },
            ]}
            no_border
            on_change={({ key }) => selectTab(key)}
          />
          {tab === 'schema' ? (
            section && <SchemaItem schema={section} />
          ) : (
            <div>
              {(examples && (
                <>
                  {Object.keys(examples).length > 1 && (
                    <Space bottom="small" top="small">
                      <Dropdown
                        data={Object.keys(examples).map((item, index) => ({
                          selected_key: `example_${index}`,
                          content: item,
                        }))}
                        on_change={({ data }) => {
                          setExample(data.content);
                        }}
                        value="example_0"
                      />
                    </Space>
                  )}

                  {activeExample && (
                    <Example
                      schema={{
                        example: (
                          examples[activeExample] as OpenAPIV3.ExampleObject
                        ).value,
                      }}
                    />
                  )}
                </>
              )) || <Example schema={section ?? {}} />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestBody;
