import { OpenAPIV3 } from 'openapi-types';

import { stringifyRemovingCyclicFields } from '../utils';
import { isDereferencedSchema, resolveSchema, Schema } from './openapi-helpers';
import { Dereferenced } from './types';

type DereferencedSchemaObject = Dereferenced<OpenAPIV3.SchemaObject>;

/**
 * @return The first subschema in the UnionSchema that is not a UnionSchema or null if none was found.
 */
function getFirstDereferencedSchema(
  schema: Schema,
): DereferencedSchemaObject | null {
  if (isDereferencedSchema(schema)) return schema;
  return schema.schemas[0]
    ? getFirstDereferencedSchema(schema.schemas[0])
    : null;
}

// eslint-disable-next-line max-lines-per-function
export function parseExample(
  unresolvedSchema: DereferencedSchemaObject,
  seen = new Set<string>(),
): unknown {
  const resolvedSchema = resolveSchema(unresolvedSchema);

  // The example will just return one possible combination
  const schema = getFirstDereferencedSchema(resolvedSchema);
  if (!schema) return null;

  if (schema.example) return schema.example;

  switch (schema.type) {
    case 'number':
    case 'integer': {
      return 0;
    }
    case 'string': {
      return 'string';
    }
    case 'boolean': {
      return true;
    }
    case 'object': {
      const stringifiedSchema = stringifyRemovingCyclicFields(schema);
      if (seen.has(stringifiedSchema)) {
        // cyclic reference, return empty object
        return {};
      }
      seen.add(stringifiedSchema);

      const data: { [p: string]: unknown } = {};

      if (schema.properties) {
        Object.keys(schema.properties).forEach((subItem) => {
          if (schema.properties && schema.properties[subItem]) {
            // @ts-ignore schema.properties[subItem] is not undefined
            data[subItem] = parseExample(schema.properties[subItem], seen);
          }
        });
      }

      if (schema.additionalProperties) {
        const addProps =
          schema.additionalProperties as DereferencedSchemaObject;

        if (addProps.properties) {
          Object.keys(addProps.properties).forEach((subItem) => {
            if (addProps.properties && addProps.properties[subItem]) {
              // @ts-ignore schema.properties[subItem] is not undefined{
              data[subItem] = parseExample(addProps.properties[subItem], seen);
            }
          });
        }
      }
      return data;
    }
    case 'array': {
      const stringifiedSchema = stringifyRemovingCyclicFields(schema);
      // cyclic reference, return empty list
      if (seen.has(stringifiedSchema)) return [];
      seen.add(stringifiedSchema);
      return schema.items ? [parseExample(schema.items, seen)] : [];
    }
    default: {
      return null;
    }
  }
}
