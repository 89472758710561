/* eslint-disable max-lines */
import {
  Drawer,
  FormRow,
  FormSet,
  InfoCard,
  Input,
  P,
  Section,
  Space,
  Tabs,
  Tag,
  ToggleButton,
} from '@dnb/eufemia';
import { filter as FilterIcon } from '@dnb/eufemia/icons';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { object } from 'zod';

import Page from '@/components/Page';
import { createDummyApi } from '@/dummyData';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

import ApiCard from './ApiCard';
import CommonRepoApiInfoCard from './components/CommonRepoApiInfoCard';
import {
  API_EXPLORER_TAB_DATA,
  API_EXPLORER_TABS,
  EXTERNAL_CLASSIFICATIONS,
  getApiClassificationDescriptionByFilter,
  INTERNAL_CLASSIFICATIONS,
  INTERNAL_FILTER,
  PUBLIC_FILTER,
} from './content';

import style from './ApiExplorerPage.module.css';

import explorerIllustrationUrl from '@/illustrations/Explorer.svg?url';

const DUMMY_APIS = new Array(10).fill(null).map((_, id) => createDummyApi(id));

interface ApiExplorerProps {
  tab?: '/explorer/apis' | '/explorer/apis/internal' | '/explorer/apis/review';
}

export default function ApiExplorerPage({
  tab = '/explorer/apis',
}: ApiExplorerProps): JSX.Element {
  const { featureFlags } = useFeatureFlags();
  const location = useLocation();
  const [, setReturnTo] = useReturnTo();
  const { user, isLoading: userLoading } = useUser();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>(PUBLIC_FILTER.All);
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const {
    data: apis,
    isValidating: apisLoading,
    mutate,
  } = useSWR<ApiDto[]>('/api');
  useEffect(() => {
    setFilter(PUBLIC_FILTER.All);
    setTagFilter([]);
    setQuery('');
  }, [tab]);

  const hasLoaded = !apisLoading && !!apis;
  const isDnbUser = isInternalUser(user?.email);

  const filteredApis = useMemo(() => {
    if (!apis) {
      return DUMMY_APIS;
    }

    const lowerCasedQuery = query.toLowerCase();
    const tagSet = new Set(tagFilter);

    const classifications =
      tab === API_EXPLORER_TABS.External
        ? EXTERNAL_CLASSIFICATIONS
        : INTERNAL_CLASSIFICATIONS;

    let basis: ApiDto[] =
      featureFlags.ENABLE_API_REVIEW_PAGE && tab === '/explorer/apis/review'
        ? apis.filter((api) => api.stage === 'review')
        : apis;

    basis = basis
      .filter((api) => classifications.has(api.classification))
      .filter(
        (api) =>
          !lowerCasedQuery || api.name.toLowerCase().includes(lowerCasedQuery),
      )
      .filter(
        (api) =>
          tagFilter.length === 0 || api.tags.some((tag) => tagSet.has(tag)),
      );

    switch (filter) {
      case PUBLIC_FILTER.All:
      case INTERNAL_FILTER.All: {
        return basis;
      }
      case PUBLIC_FILTER.Regulatory: {
        return basis.filter(({ tags }) => tags.includes('PSD2'));
      }
      case PUBLIC_FILTER.Corporate: {
        return basis.filter(({ tags }) => !tags.includes('PSD2'));
      }
      default: {
        return basis.filter(({ classification }) => classification === filter);
      }
    }
  }, [
    apis,
    query,
    tagFilter,
    tab,
    featureFlags.ENABLE_API_REVIEW_PAGE,
    filter,
  ]);

  const tagCounts = useMemo(() => {
    if (!filteredApis) {
      return {};
    }
    return filteredApis
      .flatMap((api) => api.tags)
      .reduce<Record<string, number>>((result, tag) => {
        result[tag] = result[tag] ? ++result[tag] : 1;
        return result;
      }, {});
  }, [filteredApis]);

  useEffect(() => {
    if (
      isDnbUser &&
      tab === '/explorer/apis' &&
      !featureFlags.ENABLE_MENU_REDESIGN
    ) {
      navigate('/explorer/apis/internal', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (userLoading || isDnbUser || tab == API_EXPLORER_TABS.External) {
      return;
    }

    if (user) {
      // TODO: Set global 404 stuff
      navigate('/404', { replace: true });
    } else {
      setReturnTo(location.pathname);
      navigate('/login', { replace: true });
    }
  }, [
    location.pathname,
    navigate,
    setReturnTo,
    userLoading,
    tab,
    isDnbUser,
    user,
  ]);

  const tags = useMemo(() => {
    const set = new Set<string>();

    for (const { tags } of apis || []) {
      for (const tag of tags) {
        set.add(tag);
      }
    }

    return Array.from(set);
  }, [apis]);

  //Dummy eufemia form to avoid page reload on form submit
  const { handleSubmit } = useEufemiaForm(object({}), {});
  const content = (
    <Section spacing="x-large" style_type="white">
      <FormRow>
        <ToggleButton.Group
          on_change={({ value }) => setFilter(value)}
          value={filter}
        >
          {tab === API_EXPLORER_TABS.External ? (
            <>
              <ToggleButton value={PUBLIC_FILTER.All}>All</ToggleButton>
              <ToggleButton value={PUBLIC_FILTER.Corporate}>
                Corporate APIs
              </ToggleButton>
              <ToggleButton value={PUBLIC_FILTER.Regulatory}>
                Regulatory APIs
              </ToggleButton>
            </>
          ) : (
            <>
              <ToggleButton value={INTERNAL_FILTER.All}>All</ToggleButton>
              <ToggleButton value={INTERNAL_FILTER.Domain}>Domain</ToggleButton>
              <ToggleButton value={INTERNAL_FILTER.System}>System</ToggleButton>
              <ToggleButton value={INTERNAL_FILTER.Experience}>
                Experience
              </ToggleButton>
              <ToggleButton value={INTERNAL_FILTER.Technical}>
                Technical
              </ToggleButton>
            </>
          )}
        </ToggleButton.Group>
      </FormRow>

      <ApiClassificationDescription filter={filter} />

      <FormRow bottom="large" top="medium">
        <Drawer
          right="x-small"
          title="Filters"
          triggerAttributes={{
            text: 'Filter',
            variant: 'secondary',
            icon: FilterIcon,
          }}
        >
          <FormSet on_submit={handleSubmit(() => {})} vertical>
            <FormRow top="medium">
              <ToggleButton.Group
                label="Tags"
                multiselect
                on_change={({ values }) => setTagFilter([...values])}
                values={tagFilter}
                variant="checkbox"
              >
                {tags.map((tag) => (
                  <ToggleButton key={tag} value={tag}>
                    {`${tag} (${tagCounts[tag] ?? 0})`}
                  </ToggleButton>
                ))}
              </ToggleButton.Group>
            </FormRow>
          </FormSet>
        </Drawer>
        <Tag.Group label="Filters" top="small">
          {tagFilter.map((tag) => (
            <Tag
              key={tag}
              onDelete={() =>
                setTagFilter(tagFilter.filter((candidate) => candidate !== tag))
              }
            >
              {tag}
            </Tag>
          ))}
        </Tag.Group>
      </FormRow>

      <FormRow bottom="small">
        <Input
          clear
          icon="loupe"
          on_change={({ value }) => setQuery(value)}
          placeholder="Search"
          value={query}
        />
      </FormRow>

      {filteredApis.length > 0 ? (
        <div className={style['ApiExplorer-cards']}>
          {filteredApis.map((api) => (
            <ApiCard
              api={api}
              key={api.id}
              onApiFavouriteChange={(apiId, isFavorite) =>
                mutate((apis) => {
                  if (apis) {
                    const updatedApis = [...apis];
                    const index = updatedApis.findIndex(
                      (api) => api.id === apiId,
                    );
                    updatedApis[index].isFavorite = isFavorite;
                    return [...updatedApis];
                  }
                  return;
                }, false)
              }
            />
          ))}
        </div>
      ) : featureFlags.ENABLE_COMMON_REPO_API ? (
        <CommonRepoApiInfoCard />
      ) : (
        <InfoCard text="No APIs match the selected filters." />
      )}
    </Section>
  );

  return (
    <div className="dark-mode">
      <Page
        description="Discover APIs that enable you to create great user experiences"
        illustration={
          <img
            alt="An astronaut looking at the Earth"
            className={style['Illustration']}
            src={explorerIllustrationUrl}
          />
        }
        skeleton={!hasLoaded}
        spacing={false}
        styleType="white"
        subtitle="Discover APIs"
        title="API Explorer"
      >
        {isDnbUser ? (
          <Section style_type="mint-green-25">
            <Tabs
              content_spacing={false}
              data={API_EXPLORER_TAB_DATA(
                !!featureFlags.ENABLE_API_REVIEW_PAGE,
              )}
              on_change={({ key }) => navigate(key)}
              selected_key={tab}
            >
              {content}
            </Tabs>
          </Section>
        ) : (
          content
        )}
      </Page>
    </div>
  );
}

type ApiClassificationDescription = { filter: string };
function ApiClassificationDescription({
  filter,
}: ApiClassificationDescription) {
  const description = getApiClassificationDescriptionByFilter(filter);
  const [title, content] =
    [description?.split(':')[0], description?.split(':')[1]] ?? null;

  return title && content ? (
    <Space top="medium">
      <P medium>{title}</P>
      <P top="x-small">{content}</P>
    </Space>
  ) : null;
}
