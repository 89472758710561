/* eslint-disable max-lines */
import {
  Badge,
  Button,
  Card,
  Dialog,
  Div,
  Flex,
  FormStatus,
  H2,
  H3,
  H4,
  InfoCard,
  Link,
  P,
  Section,
  Space,
  Span,
  Tabs,
} from '@dnb/eufemia';
import {
  clock_medium as ClockIcon,
  cloud_transfer_medium as CloudTransferIcon,
  lightbulb_medium as LightbulbIcon,
  trash as TrashIcon,
} from '@dnb/eufemia/icons';
import { Decision } from '@portals/shared/portal/AppApiEntitlementDto';
import {
  AccessTypes,
  type AppWithEntitlementsDto,
} from '@portals/shared/portal/AppDto';
import type { TeamDetailsDto } from '@portals/shared/portal/TeamDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { deleteApp, detachApi } from '@/api/app';
import AuthTemplate from '@/components/AuthTemplate';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import Markdown from '@/components/Markdown';
import Page from '@/components/Page';
import RouterBreadcrumb from '@/components/RouterBreadcrumb';
import { useUser } from '@/hooks/useUser';
import { convertCamelCaseToTitleCase } from '@/utils';

import ApplicationDetails from './components/ApplicationDetails';
import Collaborators from './components/Collaborators';
import Credential from './components/Credential';
import Entitlement from './components/Entitlement';
import EntitlementRequest from './components/EntitlementRequest';

import style from './Application.module.css';

export enum TabKey {
  CREDENTIALS = 'credentials',
  COLLABORATORS = 'collaborators',
}

const TABS = [
  {
    title: 'App info & Credentials',
    key: TabKey.CREDENTIALS,
  },
  {
    title: 'Collaborators',
    key: TabKey.COLLABORATORS,
  },
];

interface ApplicationProps {
  tab: TabKey;
}

const CURL_CREATE_API_WITH_API_KEYS = `
\`\`\`bash
curl --location --request POST 'https://api.prod.devportal.tech-03.net/v1/apis' \n \
--header 'X-DNBAPI-Trace-Id: {{trace-id}}' \n \
--header 'Content-Type: application/json' \n \
--header 'x-app-id: {{app-id}}' \n \
--header 'x-api-key: {{api-key}}' \n \
--data '{
    "slug": "<string>",
    "name": "<string>",
    "classification": "<string>",
    "description": "<string>",
    "enableStatus": <bool>
}'
\`\`\`
`;

const CURL_CREATE_API_WITH_OAUTH2 = `
\`\`\`bash
curl --location --request POST 'https://api.prod.devportal.tech-03.net/v1/apis' \n \
--header 'X-DNBAPI-Trace-Id: {{trace-id}}' \n \
--header 'Content-Type: application/json' \n \
--header 'Authorization: Bearer {{accessToken}}' \n \
--header 'x-app-id: {{app-id}}' \n \
--data '{
    "slug": "<string>",
    "name": "<string>",
    "classification": "<string>",
    "description": "<string>",
    "enableStatus": <bool>
}'
\`\`\`
`;

export default function Application({
  tab = TabKey.CREDENTIALS,
}: ApplicationProps) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { id, teamId } = useParams();
  const [deleteAppError, setDeleteAppError] = useState('');
  const [apiKeyError, setApiKeyError] = useState('');

  const {
    data: app,
    isValidating: appLoading,
    mutate: mutateApp,
  } = useSWR<AppWithEntitlementsDto>(`/apps/${id}`);

  const { data: team, isValidating: teamLoading } = useSWR<TeamDetailsDto>(
    teamId ? `/team/${teamId}` : null,
  );

  const apisForApp = useMemo(
    () =>
      app?.providerApis
        ? app.providerApis
            .filter(Boolean)
            .sort((api1, api2) => api1.name.localeCompare(api2.name))
        : [],
    [app?.providerApis],
  );

  const entitlementRequests = app ? app.notCompletedEntitlementRequests : [];

  const pendingEntitlementRequestApiIds = new Set(
    entitlementRequests
      .filter(
        (req) =>
          req.decision === Decision.PENDING ||
          req.decision === Decision.LEVEL1_APPROVED,
      )
      .map(({ apiId }) => apiId),
  );
  const deniedEntitlementRequestApiIds = new Set(
    entitlementRequests
      .filter((req) => req.decision === Decision.DENIED)
      .map(({ apiId }) => apiId),
  );

  const onDetachApi = useAsync(
    async (appId: string, apiId: string) => {
      await detachApi(appId, apiId);
      mutateApp();
    },
    [mutateApp],
  );

  const onDelete = useAsync(
    async (appId: string) => {
      try {
        await deleteApp(appId);
        teamId ? navigate(`/team/${teamId}`) : navigate('/profile/apps');
      } catch (error) {
        if (hasOwnProperty(error, 'message')) {
          setDeleteAppError(
            typeof error.message === 'string'
              ? error.message
              : 'Something went wrong, please try again later',
          );
        } else {
          setDeleteAppError('Something went wrong, please try again later');
        }
      }
    },
    [navigate, teamId],
  );

  const isAdmin = useMemo(() => {
    if (app && app.owner.type !== 'team') {
      return true;
    }
    return Boolean(
      team &&
        team.members.some(
          (member) => member.email === user?.email && member.isAdmin,
        ),
    );
  }, [team, app, user]);

  return (
    <AuthTemplate>
      <Page
        skeleton={appLoading || teamLoading}
        spacing={false}
        styleType="white"
        subtitle="App"
        title={app?.name}
      >
        {(onDelete.waiting || onDetachApi.waiting) && <LoadingModal />}

        <Container>
          <Section style_type="mint-green-25">
            <Tabs
              content_spacing={false}
              data={app?.owner.type == 'team' ? TABS : [TABS[0]]}
              on_change={({ key }) =>
                app &&
                navigate(
                  app?.owner.type === 'team'
                    ? `/team/${app?.owner.id}/application/${app.id}/${key}`
                    : `/application/${app?.id}/${key}`,
                  {
                    replace: true,
                  },
                )
              }
              selected_key={tab}
            />
          </Section>
          <Section spacing="large" style_type="black-3">
            <RouterBreadcrumb
              data={[
                {
                  icon: 'chevron_left',
                  text: teamId ? `Team page` : `Profile page`,
                  href: teamId ? `/team/${teamId}` : `/profile/apps`,
                },
              ]}
              goBackText={teamId ? 'Team page' : 'Profile page'}
            />
            {tab === TabKey.CREDENTIALS && app && (
              <>
                {entitlementRequests.length > 0 && (
                  <Section spacing="large" style_type="black-3">
                    <H3 bottom="small">
                      <ClockIcon /> Active requests
                    </H3>
                    <EntitlementRequest
                      entitlementRequests={entitlementRequests}
                      isAdmin={isAdmin}
                    />
                  </Section>
                )}
                <ApplicationDetails
                  appDescription={app.description}
                  appId={app.id}
                  appName={app.name}
                  isAdmin={isAdmin}
                  owner={{
                    ...app.owner,
                    name: team
                      ? team?.name
                      : `${user?.firstName} ${user?.lastName}`,
                  }}
                  project={app.project}
                />

                {app.isProviderApp && (
                  <Section spacing="large" style_type="black-3">
                    <H3 bottom="small">
                      <CloudTransferIcon /> Your APIs
                    </H3>
                    {apisForApp.length === 0 ? (
                      <Card direction="vertical">
                        <H4>
                          <Span right="medium">
                            <LightbulbIcon />
                          </Span>
                          Get started with your first API
                        </H4>
                        <Div bottom="small" left="x-large" top="small">
                          <P bottom="small">
                            You have access to the Developer Portal API. How
                            about getting started with publishing your first
                            API?
                          </P>

                          <Button
                            bottom="small"
                            element={RouterLink}
                            target="_blank"
                            to="/documentation/developer-portal-api/@default/@latest/guide"
                            variant="secondary"
                          >
                            Go to guide
                          </Button>
                          <P>Example</P>
                          <div className={style['CodeBlock']}>
                            <Markdown>
                              {app.accessType === 'API_KEYS'
                                ? CURL_CREATE_API_WITH_API_KEYS
                                : CURL_CREATE_API_WITH_OAUTH2}
                            </Markdown>
                          </div>
                        </Div>
                      </Card>
                    ) : (
                      apisForApp.map((api) => (
                        <Card bottom="small" key={api.id}>
                          <Flex.Horizontal justify="space-between">
                            <Link element={RouterLink} to={`api/${api.id}`}>
                              {api.name}
                            </Link>
                            <Badge
                              content={convertCamelCaseToTitleCase(api.stage)}
                              right="small"
                            />
                          </Flex.Horizontal>
                          <P
                            className={style['ApiCard-ApiClassification']}
                            color="black-55"
                            size="small"
                            top="small"
                          >
                            {api.classification}
                          </P>
                          <P size="small" top="small">
                            {api.description}
                          </P>
                        </Card>
                      ))
                    )}
                  </Section>
                )}
                <Section spacing="large" style_type="black-3">
                  <Credential
                    app={app}
                    error={apiKeyError}
                    isAdmin={isAdmin}
                    onApiKeyCreated={async (apiKey) => {
                      await mutateApp((appData) => {
                        if (appData) {
                          return {
                            ...appData,
                            apiKeys: [...appData.apiKeys, apiKey],
                          };
                        }
                        return;
                      }, false);
                    }}
                    onApiKeyDeleted={async (apiKeyId) => {
                      await mutateApp((appData) => {
                        if (appData) {
                          return {
                            ...appData,
                            apiKeys: appData.apiKeys.filter(
                              (apiKey) => apiKey.id !== apiKeyId,
                            ),
                          };
                        }
                        return;
                      }, false);
                    }}
                    onClientDeleted={async (clientId) => {
                      await mutateApp((appData) => {
                        if (appData) {
                          return {
                            ...appData,
                            clients: appData.clients.filter(
                              (client) => client.clientId !== clientId,
                            ),
                          };
                        }
                        return;
                      }, false);
                    }}
                    onSetError={(error) => setApiKeyError(error)}
                  />
                </Section>

                <Section spacing="large" style_type="black-3">
                  <H3>
                    <CloudTransferIcon /> API accesses
                  </H3>
                  {!app.isProviderApp && isAdmin && (
                    <Button
                      icon="add"
                      icon_position="left"
                      on_click={() =>
                        navigate(
                          app.owner.type === 'team'
                            ? `/team/${app.owner.id}/application/${app.id}/attach-api`
                            : `/application/${app.id}/attach-api`,
                        )
                      }
                      top="medium"
                    >
                      Attach new API
                    </Button>
                  )}

                  {app.currentApiAndScopeEntitlements.length === 0 ? (
                    <InfoCard
                      centered
                      text="It seems like you have not attached any APIs to your application. Click the button above to get started."
                      top="small"
                    />
                  ) : (
                    app.currentApiAndScopeEntitlements
                      .sort((a, b) =>
                        a.api.name.toUpperCase() < b.api.name.toUpperCase()
                          ? -1
                          : 1,
                      )
                      .map((entitlement) => (
                        <Space key={entitlement.api.id} top="small">
                          <Entitlement
                            entitlement={entitlement}
                            hasDeniedRequest={deniedEntitlementRequestApiIds.has(
                              entitlement.api.id,
                            )}
                            hasPendingRequest={pendingEntitlementRequestApiIds.has(
                              entitlement.api.id,
                            )}
                            isAdmin={isAdmin}
                            onDetachApi={(appId, apiId) => {
                              if (!app.isProviderApp) {
                                onDetachApi.execute(appId, apiId);
                              }
                            }}
                          />
                        </Space>
                      ))
                  )}
                </Section>
              </>
            )}

            {tab === TabKey.COLLABORATORS && team && (
              <Collaborators team={team} />
            )}
          </Section>
          {isAdmin && (
            <Section spacing="x-large" style_type="lavender">
              <H2 top="medium">Delete App</H2>
              <P bottom="medium" top="small">
                This will permanently delete your app
                {app?.accessType === AccessTypes.API_KEYS
                  ? ', API keys and'
                  : ' and'}{' '}
                API accesses.
              </P>
              <Dialog
                confirmText="Delete app"
                confirmType="warning"
                declineText="Back to app"
                description={`This will permanently delete your app${
                  app?.accessType === AccessTypes.API_KEYS
                    ? ', API keys and'
                    : ' and'
                } API accesses.
              If you need the APIs again, you will have to re-apply for access.`}
                key="delete-app"
                onConfirm={async ({ close }) => {
                  close();
                  app && (await onDelete.execute(app.id));
                }}
                title="Are you sure you want to delete the app?"
                triggerAttributes={{
                  text: 'Delete app',
                  icon: TrashIcon,
                  size: 'large',
                  bottom: 'medium',
                }}
                variant="confirmation"
              />
              <FormStatus text={deleteAppError} />
            </Section>
          )}
        </Container>
      </Page>
    </AuthTemplate>
  );
}
