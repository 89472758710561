import { Div, H3, P, Skeleton } from '@dnb/eufemia';
import { StatusDto } from '@portals/shared/portal/StatusDto';
import classNames from 'classnames';
import useSWR from 'swr';

import style from './index.module.css';

const statusTitle: Record<number, string> = {
  0: 'All APIs are operating normally',
  1: 'Some APIs may have instabilities',
  2: 'Some APIs have reduced service',
};

export default function ApiStatusWidget() {
  const { data: status, isValidating: isLoading } =
    useSWR<StatusDto[]>('/status');

  const highestSeverity =
    status && status.length > 0
      ? Math.max(...status.map(({ severity }) => severity))
      : 0;

  return (
    <Skeleton show={isLoading}>
      <Div className={style['apiStatusWidget']}>
        <div
          className={classNames(
            style['apiStatusBadge'],
            style[`apiStatusBadge-severity-${highestSeverity}`],
          )}
        />
        <div>
          <H3>{statusTitle[highestSeverity]}</H3>
          <P>
            Please see <a href="/status">here</a> for updates
          </P>
        </div>
      </Div>
    </Skeleton>
  );
}
