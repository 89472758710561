import {
  Accordion,
  Avatar,
  Button,
  Div,
  Dropdown,
  Flex,
  Hr,
  Icon,
  P,
  Span,
} from '@dnb/eufemia';
import {
  chevron_down,
  chevron_up,
  close_medium,
  log_in_medium,
  log_out_medium,
  person_medium,
} from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { logout } from '@/auth/helpers';
import useOutsideClick from '@/hooks/useOutsideClick';
import useReturnTo from '@/hooks/useReturnTo';
import { useUser } from '@/hooks/useUser';

import { openMenu } from '..';

import style from './index.module.css';

interface MenuLink {
  title: string;
  to: string;
  underline?: boolean;
  subTitle?: string;
  isVisible?: boolean;
  isProfileMenu?: boolean;
}

interface NavigationButtonElementProps {
  props: any;
  showChevron?: boolean;
}

export interface NavigationButtonProps {
  title: string;
  links?: MenuLink[];
  isProfileMenu?: boolean;
  icon?: (props: any) => any;
  setWhichMenuIsOpen?: React.Dispatch<React.SetStateAction<openMenu>>;
}

export default function NavigationButton({
  title,
  links,
  isProfileMenu = false,
  icon,
  setWhichMenuIsOpen,
}: NavigationButtonProps) {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { isLarge } = useMedia();
  const [, setReturnTo] = useReturnTo();
  const { user, isLoggedIn } = useUser();

  const handleClickOutsideDropdown = () => {
    setDropdownOpen(false);
    setWhichMenuIsOpen && setWhichMenuIsOpen(openMenu.none);
  };

  const ref = useOutsideClick(handleClickOutsideDropdown);

  const LinkItem = (link: MenuLink) => {
    return (
      <Div onPointerUp={() => navigate(link.to)}>
        <Flex.Horizontal
          align="center"
          className={
            link.isProfileMenu
              ? style['dropdownLinkProfile']
              : style['dropdownLink']
          }
          justify="space-between"
        >
          <Div className={style['dropdownTextWrapper']}>
            <P className={style['dropdownTitle']}>{link.title}</P>
            {link.subTitle && (
              <P className={style['dropdownSubtitle']} modifier="x-small">
                {link.subTitle}
              </P>
            )}
          </Div>
        </Flex.Horizontal>
      </Div>
    );
  };

  const buttonActiveClasses = dropdownOpen
    ? classNames(style['navigationButton'], style['navigationButtonActive'])
    : style['navigationButton'];

  const profileMenuVisibleClasses = dropdownOpen
    ? classNames(style['profileDropdown'], style['profileDropdownVisible'])
    : classNames(style['profileDropdown'], style['profileDropdownHidden']);

  const NavigationButtonElement = ({
    props,
    showChevron,
  }: NavigationButtonElementProps) => {
    return (
      <Div className={style['buttonContainer']} key={title} ref={ref}>
        <button {...props} className={style['navigationButton']} type="button">
          <Flex.Horizontal align="center" justify="center">
            <Div className={style['navigationIcon']}>
              <Icon icon={icon} size={24} />
            </Div>
            <Span className={style['navigationButtonText']}>{title}</Span>
          </Flex.Horizontal>
          {showChevron && (
            <div>
              <Icon icon={chevron_down} size={24} />
            </div>
          )}
        </button>
      </Div>
    );
  };

  return (
    <Div>
      {links?.length === 1 ? (
        <Div>
          {links.map((link) => (
            <NavigationButtonElement
              key={link.title}
              props={{
                onClick: () => {
                  navigate(link.to);
                },
                key: link.title,
              }}
            />
          ))}
        </Div>
      ) : isProfileMenu ? (
        <Div>
          {isLoggedIn ? (
            <Div ref={ref} role="menuitems">
              <Div className={style['profileMenuWrapper']}>
                <Div className={style['avatarWrapper']}>
                  {dropdownOpen && !isLarge ? (
                    <Button
                      icon={close_medium}
                      icon_position="top"
                      left
                      on_click={() => {
                        setDropdownOpen(false);
                        setWhichMenuIsOpen!(openMenu.none);
                      }}
                      size="large"
                      text="Close"
                      variant="tertiary"
                    />
                  ) : (
                    <div
                      className={style['Avatar']}
                      onClick={() => {
                        setDropdownOpen(!dropdownOpen);
                        setWhichMenuIsOpen!(openMenu.ProfileDropDown);
                      }}
                    >
                      <Avatar size="medium" variant="secondary">
                        {user?.firstName}
                      </Avatar>
                    </div>
                  )}
                </Div>
                <div className={profileMenuVisibleClasses}>
                  <div className={style['arrowUp']} />
                  <ul className="dnb-unstyled-list" id="profileDropdownList">
                    <li
                      className={isLarge ? '' : style['buttonContainer']}
                      onClick={() => navigate('/profile/teams')}
                    >
                      <div className={isLarge ? '' : style['navigationButton']}>
                        <Flex.Horizontal
                          align="center"
                          className={style['dropdownLinkProfile']}
                        >
                          <Flex.Item grow>
                            {isLarge ? (
                              <P modifier="basis">My profile</P>
                            ) : (
                              <Span className={style['navigationButtonText']}>
                                My profile
                              </Span>
                            )}
                          </Flex.Item>
                          <Icon icon={person_medium} />
                        </Flex.Horizontal>
                      </div>
                    </li>
                    {isLarge && (
                      <div className={style['profileDropdownItemDivider']}>
                        <Hr />
                      </div>
                    )}
                    <li
                      className={isLarge ? '' : style['buttonContainer']}
                      onClick={logout}
                    >
                      <div className={isLarge ? '' : style['navigationButton']}>
                        <Flex.Horizontal
                          align="center"
                          className={style['dropdownLinkProfile']}
                        >
                          <Flex.Item grow>
                            {isLarge ? (
                              <P modifier="basis">Log out</P>
                            ) : (
                              <Span className={style['navigationButtonText']}>
                                Log out
                              </Span>
                            )}
                          </Flex.Item>

                          <Icon icon={log_out_medium} size={24} />
                        </Flex.Horizontal>
                      </div>
                    </li>
                  </ul>
                </div>
              </Div>
            </Div>
          ) : (
            <Button
              element={RouterLink}
              icon={log_in_medium}
              icon_position="top"
              on_click={() => {
                setReturnTo(location.pathname);
              }}
              size="large"
              to="/login"
              variant="tertiary"
            >
              Log in
            </Button>
          )}
        </Div>
      ) : isLarge ? (
        <Dropdown
          action_menu
          data={() => {
            return links?.map((link) => (
              <LinkItem
                key={link.title}
                subTitle={link.subTitle}
                title={link.title}
                to={link.to}
              />
            ));
          }}
          icon_position="right"
          id="menu"
          triangle_position="left"
          trigger_element={(props) => (
            <button type="button" {...props} className={buttonActiveClasses}>
              <Div className={style['navigationIcon']}>
                <Icon icon={icon} size={24} />
              </Div>
              <Span className={style['navigationButtonText']}>{title}</Span>

              <Icon
                className={
                  dropdownOpen
                    ? style['chevron']
                    : classNames(style['chevron'], style['chevronRotate'])
                }
                icon={chevron_up}
              />
            </button>
          )}
          variant="tertiary"
        />
      ) : (
        <Accordion
          element={(props: any) => (
            <NavigationButtonElement props={props} showChevron />
          )}
          id="burgerMenuAccordion"
          left_component={<Icon icon={icon} size={24} />}
        >
          {links?.map((link) => (
            <LinkItem
              key={link.title}
              subTitle={link.subTitle}
              title={link.title}
              to={link.to}
            />
          ))}
        </Accordion>
      )}
    </Div>
  );
}
