import {
  Accordion,
  Button,
  FormRow,
  H1,
  H2,
  H3,
  Img,
  Link,
  P,
  Section,
  Skeleton,
  Space,
} from '@dnb/eufemia';
import {
  cloud_transfer_medium as CloudTransferMediumIcon,
  compose_medium as ComposeMediumIcon,
} from '@dnb/eufemia/icons';
import type { StatusDto } from '@portals/shared/portal/StatusDto';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import Container from '@/components/Container';
import Footer from '@/components/Footer';
import MenuBar from '@/components/MenuBar';
import StatusWidget from '@/components/StatusWidget';
import useAuth from '@/hooks/useAuth';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import { API_DEFINITIONS, GETTING_STARTED_STEPS } from './LandingContent';
import LandingNew from './LandingNew';

import style from './Landing.module.css';

import groupInFrontOfWhiteboardIllustrationUrl from '@/illustrations/GroupInFrontOfWhiteboard.svg?url';

export default function Landing(): JSX.Element {
  const { isSignedIn, waiting } = useAuth();
  const { featureFlags, isLoading: loadingFlags, mutate } = useFeatureFlags();
  const { data: status, isValidating: statusLoading } =
    useSWR<StatusDto[]>('/status');

  const severity = status
    ? Math.max(...status.map(({ severity }) => severity))
    : 0;
  const loading = waiting || loadingFlags || statusLoading;
  useEffect(() => {
    mutate();
  }, [isSignedIn, mutate]);

  return (
    <Skeleton show={loadingFlags}>
      {featureFlags.ENABLE_NEW_REDESIGN ? (
        <LandingNew />
      ) : (
        <div className={style['Landing']}>
          <header className={style['Header']}>
            <MenuBar />
            <Section spacing="xx-large" style_type="transparent">
              <Container centered>
                <div className={style['Header-text']}>
                  <H1>
                    <span className={style['Header-subtitle']}>Welcome to</span>
                    <span>DNB Developer</span>
                  </H1>
                  <P top="large">
                    DNB Developer aims to help you make awesome services by
                    providing access to DNB&apos;s data and services through
                    APIs. Of course, we will never share customer data unless
                    the customer gives explicit consent.
                  </P>
                  <FormRow top="medium" vertical>
                    <Button
                      element={RouterLink}
                      icon={CloudTransferMediumIcon}
                      icon_position="left"
                      icon_size="medium"
                      size="large"
                      to="/explorer/apis"
                    >
                      Browse APIs
                    </Button>
                    {isSignedIn ? (
                      <Button
                        element={RouterLink}
                        icon={ComposeMediumIcon}
                        icon_position="left"
                        icon_size="medium"
                        size="large"
                        skeleton={loading}
                        to="/application/new"
                        top="small"
                        variant="secondary"
                      >
                        Create app
                      </Button>
                    ) : (
                      <Button
                        element={RouterLink}
                        icon={ComposeMediumIcon}
                        icon_position="left"
                        icon_size="medium"
                        size="large"
                        to="/sign-up"
                        top="small"
                        variant="secondary"
                      >
                        Sign up
                      </Button>
                    )}
                  </FormRow>
                </div>
              </Container>
            </Section>
          </header>

          <Container bottom="x-large" centered>
            <StatusWidget severity={severity} skeleton={statusLoading} />
          </Container>

          <Section spacing="xx-large" style_type="transparent">
            <Container centered>
              <Container size="small">
                <H2 size="large">Live APIs</H2>
                <P top="large">
                  These APIs are available in live mode with real data and are
                  ready to be used after successful grant of access. They are
                  also available in the test environment for you to test.
                </P>
              </Container>

              {API_DEFINITIONS.map((api, i) => (
                <Space className={style['Api']} key={i}>
                  <figure className={style['Api-illustration']}>
                    {api.illustration}
                  </figure>
                  <div>
                    <H3 size="large">{api.name}</H3>
                    <P top="small">{api.description}</P>
                    <Space className={style['Api-buttons']} top="medium">
                      <Button
                        element={RouterLink}
                        size="large"
                        to={api.productPageUrl}
                      >
                        Learn more
                      </Button>
                      <Button
                        element={RouterLink}
                        size="large"
                        to={api.documentationUrl}
                        variant="secondary"
                      >
                        Documentation
                      </Button>
                    </Space>
                  </div>
                </Space>
              ))}
              <P className={style['Callout']}>
                <Button
                  element={RouterLink}
                  icon="arrow_right_medium"
                  size="large"
                  to="/explorer/apis"
                  variant="secondary"
                >
                  Check out all our APIs
                </Button>
              </P>
            </Container>
          </Section>

          <Section spacing="xx-large" style_type="transparent">
            <Container centered>
              <H2 size="x-large">Let&apos;s get started!</H2>
              <P top="small">
                Follow these steps to get up and running with our{' '}
                <Link element={RouterLink} to="/explorer/apis">
                  APIs
                </Link>
                .
              </P>

              <Accordion.Provider
                prevent_rerender
                remember_state
                single_container
                top="large"
              >
                {GETTING_STARTED_STEPS.map((step, i) => {
                  return (
                    <Accordion
                      bottom
                      expanded={i === 0}
                      group="getting-started"
                      key={i}
                      title={`${i + 1}. ${step.title}`}
                    >
                      <Space
                        className={style['GettingStarted-step']}
                        left="large"
                        top="large"
                      >
                        <H3>{step.title}</H3>
                        <Img
                          alt={step.title}
                          height={180}
                          src={step.illustration}
                          top="medium"
                        />
                        <P top="medium">{step.content}</P>
                      </Space>
                    </Accordion>
                  );
                })}
              </Accordion.Provider>
            </Container>
          </Section>

          <Section spacing="xx-large" style_type="mint-green-25" top="xx-large">
            <div className={style['Career']}>
              <H2 className={style['Career-header']} size="large">
                Join our team
              </H2>
              <img
                alt="Man and woman infront of whiteboard"
                className={style['Career-illustration']}
                src={groupInFrontOfWhiteboardIllustrationUrl}
              />
              <div className={style['Career-text']}>
                <P>
                  DNB Open Banking is a rapidly-growing technology team within
                  DNB. Our mission is to build products that enable developers
                  to create exceptional user experiences. We build
                  developer.dnb.no and the API products that go with it.
                </P>

                <P top="x-large">
                  Being part of Open Banking will give you the possibility to
                  drive and enable innovation, so if you have hunger for
                  intrapreneurship in a big company, join our team!
                </P>

                <Button
                  element={RouterLink}
                  size="large"
                  to="/jobs"
                  top="medium"
                >
                  View careers
                </Button>
              </div>
            </div>
          </Section>
        </div>
      )}
      <Footer />
    </Skeleton>
  );
}
