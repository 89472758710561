import {
  Button,
  Checkbox,
  FormRow,
  FormSet,
  FormStatus,
  Textarea,
} from '@dnb/eufemia';
import { ATLEAST_N, ATMOST_N } from '@portals/shared/common/messages';
import { type CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks/useEufemiaForm';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { requestLiveMode } from '@/api/app';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';
import RequestScopes from '@/pages/profile/apps/application/components/RequestScopes';

import TermsOfUseLabel from '../TermsOfUseLabel';

type PortalApiAccessFormModalProps = {
  onClose: () => void;
  entitlement: CurrentApiAndScopeEntitlementsDto;
};

const appApiEntitlementRequestSchema = z.object({
  useCase: z
    .string({
      required_error:
        'A proper use case is required to get access to this API.',
    })
    .min(5, ATLEAST_N(5))
    .max(500, ATMOST_N(500)),
  termsOfUseAccepted: z
    .boolean({
      required_error:
        'Terms of use must be accepted for to get access to this API.',
    })
    .optional()
    .refine((value) => value !== false),
  scopeIds: z.string().array().min(1, 'Please select at least one scope.'),
});

export default function PortalApiAccessFormModal({
  onClose,
  entitlement,
}: PortalApiAccessFormModalProps): JSX.Element {
  const { id: appId } = useParams();
  const navigate = useNavigate();

  const existingScopeIds = entitlement?.scopeEntitlements
    .filter((scope) => !scope.liveMode)
    .map(({ scopeId }) => scopeId);

  const scopes = entitlement.api.scopes?.map((scope) => {
    if (existingScopeIds?.includes(scope.id)) {
      return {
        id: scope.id,
        name: scope.name,
        description: scope.description,
        selected: true,
      };
    }
    return {
      id: scope.id,
      name: scope.name,
      description: scope.description,
      selected: false,
    };
  });

  const {
    controller,
    controller: { setValue },
    handleSubmit,
    register,
    submitting,
  } = useEufemiaForm(appApiEntitlementRequestSchema, {
    termsOfUseAccepted: entitlement.api.termsOfUse ? false : undefined,
    scopeIds: scopes?.filter(({ selected }) => selected).map(({ id }) => id),
  });

  const onScopeChange = (scopeId: string, checked: boolean) => {
    if (checked) {
      setValue('scopeIds', [...(controller.values.scopeIds || []), scopeId]);
      controller.triggerValidation('scopeIds');
    } else {
      setValue(
        'scopeIds',
        controller.values?.scopeIds?.filter((id) => scopeId !== id),
      );
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (appId) {
      await requestLiveMode(appId, {
        apiId: entitlement.api.id,
        termsOfUseAccepted: data.termsOfUseAccepted,
        useCase: data.useCase,
        liveMode: true,
        scopeIds: data.scopeIds,
      });
      navigate(
        '/success?title=Access request sent!&subtitle=Sit back and relax while it is being handled.',
        { replace: true },
      );
    }
  });

  return (
    <ModalPage
      actualModal
      onClose={onClose}
      subtitle={`You are now in the process of requesting access to ${
        entitlement.api.approvalStage === 'test' ? 'test and live' : 'the live'
      } environment`}
      title={`${
        entitlement.api.approvalStage === 'test' ? 'Test and Live' : 'Live'
      } environment request`}
    >
      {submitting && <LoadingModal />}

      <div>
        <FormSet direction="vertical" on_submit={onSubmit}>
          <FormRow>
            <RequestScopes
              apiDescription={entitlement.api.description}
              apiName={entitlement.api.name}
              onChange={onScopeChange}
              scopes={scopes ?? []}
            />
          </FormRow>
          {controller.getError('scopeIds') && (
            <FormRow top="x-small">
              <FormStatus text={controller.getError('scopeIds')} />
            </FormRow>
          )}

          <FormRow top="small">
            <Textarea
              label="Use case"
              label_direction="vertical"
              placeholder="Please provide some information of how you are going to use the API"
              rows={5}
              stretch
              {...register.textarea('useCase')}
            />
          </FormRow>

          {entitlement.api.termsOfUse && (
            <FormRow top="small">
              <Checkbox
                label={
                  <TermsOfUseLabel
                    apiName={entitlement.api.name}
                    termsOfUse={entitlement.api.termsOfUse}
                    termsOfuseUpdatedAt={entitlement.api.termsOfUseUpdatedAt}
                  />
                }
                {...register.checkbox('termsOfUseAccepted')}
              />
            </FormRow>
          )}
          <FormRow top="large">
            <Button type="submit">Submit request</Button>
          </FormRow>
        </FormSet>
      </div>
    </ModalPage>
  );
}
