import { Card, Icon, P } from '@dnb/eufemia';
import FlexItem from '@dnb/eufemia/components/flex/Item';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import style from './index.module.css';

interface TileProps {
  title: string;
  description: string;
  linkTo: string;
  icon?: (props: ReactNode) => ReactNode;
}

export default function Tile({
  title,
  description,
  linkTo,
  icon,
}: TileProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Card
      align="stretch"
      className={style['tile']}
      id="tile"
      onPointerUp={() => navigate(linkTo)}
      rowGap="small"
      tabIndex={0}
    >
      {icon && (
        <FlexItem alignSelf="center">
          <Icon color="seagreen" icon={icon} size={46} />
        </FlexItem>
      )}
      <FlexItem alignSelf="center">
        <P modifier="basis medium" size="medium">
          {title}
        </P>
      </FlexItem>
      <FlexItem alignSelf="center">
        <P style={{ textAlign: 'center' }}>{description}</P>
      </FlexItem>
    </Card>
  );
}
