/* eslint-disable max-lines */
import '@dnb/eufemia/style';

import { MsalProvider } from '@azure/msal-react';
import { Provider, Theme } from '@dnb/eufemia/shared';
import { ThemeProvider } from '@emotion/react';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useClearCache } from 'react-clear-cache';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { type Fetcher, SWRConfig } from 'swr';

import SsoAzureLogin from '@/auth/SsoAzureLogin';
import CenteredProgressIndicator from '@/components/ProgressIndicator';
import DebugDuckyPage from '@/pages/debug-ducky';
import EventPage from '@/pages/events/[slug]/EventPage';
import ApiExplorerPage from '@/pages/explorer/apis/ApiExplorerPage';
import ApiFromCommonRepo from '@/pages/explorer/apis/ApiFromCommonRepo';
import EventExplorerPage from '@/pages/explorer/events/EventExplorerPage';
import ExplorerPage from '@/pages/explorer/ExplorerPage';
import GettingStarted from '@/pages/getting-started/GettingStarted';
import LoginFailure from '@/pages/login/LoginFailure';
import NotFound from '@/pages/not-found/NotFound';
import Sitemap from '@/pages/sitemap/Sitemap';
import Status from '@/pages/status/Status';
import ManageSubscriptions from '@/pages/subscriptions/ManageSubscriptions';
import NewsSubscriptions from '@/pages/subscriptions/NewsSubscriptions';
import ReleasesSubscriptions from '@/pages/subscriptions/ReleasesSubscriptions';
import StatusSubscriptions from '@/pages/subscriptions/StatusSubscriptions';
import Unsubscribe from '@/pages/subscriptions/Unsubscribe';
import { allIsAwesome } from '@/theme/awesome';
import { darkTheme } from '@/theme/dark';
import { defaultTheme, ThemeMapping } from '@/theme/default';

import App from './App';
import { msalInstance } from './auth/azure';
import SuccessModal from './components/SuccessModal';
import useFeatureFlags from './hooks/useFeatureFlags';
import AggregationProviders from './pages/aggregation-providers/AggregationProviders';
import ApiTermsOfUse from './pages/api-terms-of-use/ApiTermsOfUse';
import { API_EXPLORER_TABS } from './pages/explorer/apis/content';
import GlobalErrorPage from './pages/global-error/GlobalErrorPage';
import Jobs from './pages/jobs/Jobs';
import Landing from './pages/landing/Landing';
import ForgotPassword from './pages/login/ForgotPassword';
import Login from './pages/login/Login';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import ApiStatistics from './pages/profile/apps/application/api-stats';
import Application, {
  TabKey,
} from './pages/profile/apps/application/Application';
import NewPage from './pages/profile/teams/team-pages/NewPage';
import SignUp from './pages/sign-up/SignUp';
import SignUpSuccess from './pages/sign-up/SignUpSucces';
import TermsOfUse from './pages/terms-of-use/TermsOfUse';
import { ApiError, request } from './request';
import { AwsRumProvider } from './rum';

import './index.css';

const ChannelStatistics = lazy(
  () => import('@/pages/channel-statistics/ChannelStatistics'),
);
const ApiDocumentation = lazy(
  () => import('@/pages/api-documentation/ApiDocumentation'),
);
const News = lazy(() => import('./pages/news/News'));
const HelpCenter = lazy(() => import('./pages/help-center/HelpCenter'));
const SupportFlow = lazy(() => import('./pages/help-center/SupportFlow'));
const Faq = lazy(() => import('@/pages/help-center/faq/Faq'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const OAuthIntegrationInfoRequester = lazy(
  () => import('./pages/OAuthIntegrationInfoRequesterPage'),
);

const CreateTeam = lazy(() => import('./pages/profile/teams/CreateTeam'));
const GeneratePsd2Certificate = lazy(
  () => import('./pages/profile/psd2/GeneratePsd2Certificate'),
);
const EnableMfa = lazy(() => import('./pages/mfa/EnableMfa'));
const CreateApp = lazy(() => import('./pages/profile/apps/createApp'));
const CreateClient = lazy(
  () => import('./pages/profile/apps/application/components/CreateClient'),
);

const ImportClient = lazy(
  () => import('./pages/profile/apps/application/components/ImportClient'),
);

const AttachApi = lazy(() => import('./pages/profile/apps/attachApi'));
const EditApp = lazy(() => import('./pages/profile/apps/editApp'));
const EditProject = lazy(() => import('./pages/profile/apps/editProject'));
const AddTeamMembers = lazy(
  () => import('./pages/profile/teams/AddTeamMembers'),
);
const TeamPages = lazy(
  () => import('./pages/profile/teams/team-pages/TeamPages'),
);

const EditTeam = lazy(() => import('./pages/profile/teams/EditTeam'));
const Team = lazy(() => import('./pages/profile/teams/Team'));
const Currencies = lazy(() => import('./pages/products/currencies/Currencies'));
const DelegatedAuthentication = lazy(
  () =>
    import('./pages/products/delegated-authentication/DelegatedAuthentication'),
);
const Accounts = lazy(() => import('./pages/products/psd2/Accounts'));
const Payments = lazy(() => import('./pages/products/psd2/Payments'));
const ScopeOverview = lazy(() => import('./pages/scope-overview'));

const RUM_DATA_ENDPOINT = 'https://dataplane.rum.eu-west-1.amazonaws.com';
const APPLICATION_REGION = 'eu-west-1';

declare global {
  interface Window {
    version: {
      version: string;
    };
  }
}

let localTheme = {};
let localThemeName = null;
if (localStorage.getItem('theme') && localStorage.getItem('theme')) {
  localTheme =
    localStorage.getItem('theme') === 'awesome' ? allIsAwesome : darkTheme;
  localThemeName = localStorage.getItem('theme');
}

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => navigate(to, { replace: true }), [navigate, to]);
  return null;
};

const fetcher: Fetcher = async (url: string) => request(url, 'get');

function Site(): JSX.Element {
  const [error, setError] = useState<unknown>();
  const { featureFlags } = useFeatureFlags();

  const onError = (error: Error) => {
    setError(error);
  };
  const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache({
    auto: true,
    duration: 30 * 1000, // Checks every 30 second
  });

  useEffect(() => {
    window.version = { version: `${APP_VERSION}.${GIT_HASH}` };
  }, []);

  useEffect(() => {
    if (!isLatestVersion && featureFlags.ENABLE_AUTO_REFRESH_ON_UPDATE) {
      emptyCacheStorage();
    }
  }, [
    isLatestVersion,
    emptyCacheStorage,
    latestVersion,
    featureFlags.ENABLE_AUTO_REFRESH_ON_UPDATE,
  ]);

  if (error) {
    if (ApiError.isApiError(error) && error.status === 404) {
      return <GlobalErrorPage status="404" />;
    } else {
      return <GlobalErrorPage status="500" />;
    }
  }

  const appHome = (
    <>
      <Route path="application/:id">
        <Route element={<Application tab={TabKey.CREDENTIALS} />} index />
        <Route
          element={<Application tab={TabKey.CREDENTIALS} />}
          path="credentials"
        />

        <Route
          element={<Application tab={TabKey.COLLABORATORS} />}
          path="collaborators"
        />
        <Route
          element={
            <Suspense fallback={<CenteredProgressIndicator />}>
              <EditApp />
            </Suspense>
          }
          path="edit"
        />
      </Route>
      <Route
        element={
          <Suspense fallback={<CenteredProgressIndicator />}>
            <CreateApp />
          </Suspense>
        }
        path="application/new"
      />
      <Route
        element={
          <Suspense fallback={<CenteredProgressIndicator />}>
            <AttachApi />
          </Suspense>
        }
        path="application/:appId/attach-api"
      />
      <Route
        element={
          <Suspense fallback={<CenteredProgressIndicator />}>
            <CreateClient />
          </Suspense>
        }
        path="application/:appId/create-client"
      />
      <Route
        element={
          <Suspense fallback={<CenteredProgressIndicator />}>
            <ImportClient />
          </Suspense>
        }
        path="application/:appId/import-client"
      />
      <Route
        element={
          <Suspense fallback={<CenteredProgressIndicator />}>
            <ApiStatistics />
          </Suspense>
        }
        path="application/:appId/api/:apiId"
      />
    </>
  );

  const projectRoute = (
    <Route path="project/:id">
      <Route
        element={
          <Suspense fallback={<CenteredProgressIndicator />}>
            <EditProject />
          </Suspense>
        }
        path="edit"
      />
    </Route>
  );

  return (
    <AwsRumProvider
      allowCookies
      enableXRay={false}
      endpoint={RUM_DATA_ENDPOINT}
      guestRoleArn={RUM_GUEST_ROLE_ARN}
      id={RUM_APPLICATION_ID}
      identityPoolId={RUM_IDENTITY_POOL_ID}
      region={APPLICATION_REGION}
      sessionSampleRate={1}
      telemetries={['http', 'errors', 'performance']}
      version={APP_VERSION}
    >
      <MsalProvider instance={msalInstance}>
        <SWRConfig
          value={{
            fetcher,
            revalidateOnFocus: false,
            onError,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route element={<NotFound />} path="*" />
              <Route element={<App />} path="/">
                <Route element={<Landing />} index />
                <Route element={<SuccessModal />} path="success" />
                <Route
                  element={<ApiTermsOfUse />}
                  path="documentation/:apiSlug/tou"
                />
                <Route element={<Jobs />} path="jobs" />

                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <HelpCenter />
                    </Suspense>
                  }
                  path="help-center"
                />
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <SupportFlow />
                    </Suspense>
                  }
                  path="help-center/:id"
                />
                <Route path="help-center/faq">
                  <Route element={<Redirect to="psd2" />} index />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <Faq />
                      </Suspense>
                    }
                    path=":tab"
                  />
                </Route>
                <Route
                  element={<Redirect to="/help-center" />}
                  path="support"
                />

                <Route element={<TermsOfUse />} path="terms-of-use" />
                <Route element={<PrivacyPolicy />} path="privacy-policy" />
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <News />
                    </Suspense>
                  }
                  path="news"
                />
                <Route element={<Status />} path="status" />
                <Route element={<Sitemap />} path="site-map" />

                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <ChannelStatistics />
                    </Suspense>
                  }
                  path="channel-statistics"
                />
                <Route element={<SsoAzureLogin />} path="/sso" />
                <Route path="login">
                  <Route element={<Login />} index />
                  <Route element={<LoginFailure />} path="failure" />
                  <Route
                    element={<ForgotPassword />}
                    path="forgotten-password"
                  />
                </Route>
                <Route path="sign-up">
                  <Route element={<SignUp />} index />
                  <Route element={<SignUpSuccess />} path="success" />
                </Route>
                <Route element={<NotFound />} path="/not-found" />

                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <EnableMfa />
                    </Suspense>
                  }
                  path="mfa/enable"
                />

                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <Accounts />
                    </Suspense>
                  }
                  path="accounts"
                />
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <Payments />
                    </Suspense>
                  }
                  path="payments"
                />
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <Currencies />
                    </Suspense>
                  }
                  path="currencies"
                />
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <DelegatedAuthentication />
                    </Suspense>
                  }
                  path="delegated-authentication"
                />

                <Route element={<AggregationProviders />} path="aggregation" />

                {appHome}
                {projectRoute}
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <OAuthIntegrationInfoRequester />
                    </Suspense>
                  }
                  path="integrate-servicenow"
                />

                <Route path="profile">
                  <Route element={<Redirect to="teams" />} index />
                  <Route path="apps">
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <Profile tab="apps" />
                        </Suspense>
                      }
                      index
                    />
                  </Route>
                  <Route path="teams">
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <Profile tab="teams" />
                        </Suspense>
                      }
                      index
                    />
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <CreateTeam />
                        </Suspense>
                      }
                      path="new"
                    />
                  </Route>
                  <Route path="psd2">
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <Profile tab="psd2" />
                        </Suspense>
                      }
                      index
                    />
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <GeneratePsd2Certificate />
                        </Suspense>
                      }
                      path="generate-certificate"
                    />
                  </Route>
                  <Route path="account">
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <Profile tab="account" />
                        </Suspense>
                      }
                      index
                    />
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <EnableMfa />
                        </Suspense>
                      }
                      path="mfa/enable"
                    />
                  </Route>
                </Route>

                <Route path="explorer">
                  <Route element={<ExplorerPage />} index />
                  <Route element={<EventExplorerPage />} path="events" />
                  <Route
                    element={
                      <ApiExplorerPage tab={API_EXPLORER_TABS.External} />
                    }
                    path="apis"
                  />
                  <Route
                    element={<ApiExplorerPage tab={API_EXPLORER_TABS.Review} />}
                    path="apis/review"
                  />
                  <Route
                    element={
                      <ApiExplorerPage tab={API_EXPLORER_TABS.Internal} />
                    }
                    path="apis/internal"
                  />
                </Route>
                <Route path="events">
                  <Route element={<EventPage />} path=":slug" />
                </Route>
                <Route path="api-contracts/*">
                  <Route element={<ApiFromCommonRepo />} path="*" />
                </Route>
                <Route caseSensitive path="documentation">
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <ApiDocumentation />
                      </Suspense>
                    }
                    path=":api/:environment/:version"
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <ApiDocumentation />
                      </Suspense>
                    }
                    path=":api/:environment/:version/:tab"
                  />
                </Route>

                <Route
                  element={<ManageSubscriptions />}
                  path="subscriptions/manage/:id"
                />

                <Route
                  element={<ManageSubscriptions />}
                  path="subscriptions/manage"
                />

                <Route path="team/:teamId">
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <Team />
                      </Suspense>
                    }
                    index
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <TeamPages />
                      </Suspense>
                    }
                    path="pages/:contentId"
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <NewPage />
                      </Suspense>
                    }
                    path="pages/new"
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <CreateApp />
                      </Suspense>
                    }
                    path="application/new"
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <EditTeam />
                      </Suspense>
                    }
                    path="edit-team"
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <AttachApi />
                      </Suspense>
                    }
                    path="application/:appId/attach-api"
                  />
                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <CreateClient />
                      </Suspense>
                    }
                    path="application/:appId/create-client"
                  />

                  <Route
                    element={
                      <Suspense fallback={<CenteredProgressIndicator />}>
                        <AddTeamMembers isNewTeam />
                      </Suspense>
                    }
                    path="invite-users"
                  />
                  <>
                    <Route
                      element={
                        <Suspense fallback={<CenteredProgressIndicator />}>
                          <CreateApp />
                        </Suspense>
                      }
                      path="application/new"
                    />
                    {appHome}
                    {projectRoute}
                  </>
                </Route>

                <Route element={<GettingStarted />} path="getting-started" />
                <Route
                  element={<SsoAzureLogin />}
                  path="login-complete/azure"
                />
                <Route
                  element={<ReleasesSubscriptions />}
                  path="/subscriptions/releases/:apiSlug"
                />
                <Route
                  element={<NewsSubscriptions />}
                  path="/subscriptions/news"
                />
                <Route
                  element={<StatusSubscriptions />}
                  path="/subscriptions/status"
                />
                <Route element={<Unsubscribe />} path="/unsubscribe" />
                <Route
                  element={
                    <Suspense fallback={<CenteredProgressIndicator />}>
                      <ScopeOverview />
                    </Suspense>
                  }
                  path="scope-overview"
                />
                <Route element={<DebugDuckyPage />} path="/debug-ducky" />
              </Route>
            </Routes>
          </BrowserRouter>
        </SWRConfig>
      </MsalProvider>
    </AwsRumProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeMapping>
      <Theme propMapping={`theme-${localThemeName}`}>
        <ThemeProvider theme={defaultTheme}>
          <ThemeProvider theme={localTheme}>
            <Provider locale="en-GB">
              <Site />
            </Provider>
          </ThemeProvider>
        </ThemeProvider>
      </Theme>
    </ThemeMapping>
  </React.StrictMode>,
  document.querySelector('#app'),
);
